<template>
  <div class="pa-5">
    <div v-if="event_ready">

      {{event.name}}

      <div v-for="near in nearEvents" :key="near.id">
        {{near.date.day}} {{near.date.month_rname}} {{near.date.year}} - {{near.date.time}}
      </div>



    </div>

  </div>
</template>

<script>
export default {
  name: "Event",

  data: () => ({

    id: 0,

    event_ready: false,
    event: {},

    nearEvents:[],

    more: false,



    // афиша нужна - чтоб показывать близкие спектакли из этого блоко
    afisha_ready:false,
    afisha:[],
    time_index:[],
    months:["Январь","Февраль","Март","Апрель","Май","Июнь","Июль","Август","Сентябрь","Октябрь","Ноябрь","Декабрь"],
    months_small:["янв","фев","март","апр","май","июнь","июль","авг","сен","окт","ноя","дек"],

  }),

  created() {

    //let t = 0;

    this.id = this.$route.params.id;

    console.log(this.id);

    if (this.$store.getters.isAuthenticated){

      this.loadEvent(this.id);

    }





  },


  methods:{

    getNearEvents(){

      console.log(this.event);
      console.log(this.afisha);

      this.nearEvents = this.afisha.filter(item=>item.show_id===this.event.show_id);

      console.log(this.nearEvents);


    },



    loadEvent(id){

      let pr2 = this.$store.state.api.getEventUser(id);

      pr2.then( data =>{

        this.event = data;
        this.event_ready = true;

        this.getNearEvents();

      })

      this.afisha_ready = this.$store.state.afisha.loaded;
      this.afisha       = this.$store.state.afisha.afisha;
      this.time_index   = this.$store.state.afisha.time_index;

    },






  }
}
</script>

<style scoped>

</style>
